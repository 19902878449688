.animated-btn {
  border: 2px solid currentColor;
  border-radius: 10px;
  color: #69c36d;
  font-size: 15px;
  font-weight: 500;
  overflow: hidden;
  padding: 5px 15px;
  position: relative;
  text-decoration: none;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;
}

.animated-btn::after {
  background-color: #69c36d;
  border-radius: 10px;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: 0.3s transform ease-out;
  will-change: transform;
  z-index: -1;
}

.animated-btn:hover::after {
  transform: translate(0, 0);
}

.animated-btn:hover {
  border: 2px solid transparent;
  color: #445747;
  transform: scale(1.05);
  will-change: transform;
}
