body {
  margin: 0;
  background: #445747;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* scroll bar styling */
:root {
  --primary: #fbc8c8;
  /* --primary: #445747; */
  --secondary: #69c36d;
}

*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 2px solid var(--primary);
}

@layer base {
  html {
    scroll-behavior: smooth;
  }
}
