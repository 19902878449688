header {
  width: 100px;
  height: 100%;
  background: #1f1d2b;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
}

header img {
  width: 5rem;
  padding-top: 15px;
}

header div {
  padding-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header div li {
  list-style: none;
  margin: 1rem 0;
  position: relative;
  color: #69c36d;
  width: 100%;
  text-align: center;
}

.top-menu .side-icon {
  margin-top: 2.2rem;
}

header div a:hover {
  font-size: 32px;
  color: #fff;
}

header div a:focus {
  color: #fff;
}

header div a {
  font-size: 24px;
  color: #69c36d;
  text-decoration: none;
  transition: all 0.3s;
}

.bottom-menu {
  margin-top: auto;
  margin-bottom: 25px;
}

@media only screen and (max-width: 525px) {
  header {
    min-width: 70px;
  }
}
